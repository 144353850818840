import React from "react";

export const Oneplatfom = (props) => {
    return (
        <div id="features" className="text-center">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-sm-12 col-xs-12 col-md-12">
                        <h1>ONE PLATFORM MANY SOLUTIONS</h1>
                        <p style={{ fontSize: "15px" }}>
                        Update your menu anytime with ease, add photos and descriptions, set prices and discounts, and much more.
                        </p>
                        {""}
                        {""}
                        <a
                            href="https://app.dagizo.io/"
                            className="btn btn-custom3 btn-lg page-scroll"
                        >
                            Sign Up Now
                        </a>
                    </div>{" "}
                    {""}
                    {/* <div className="row col-lg-12 col-sm-12 col-xs-12"> */}
                        
                        {props.data
                            ? props.data.map((d, i) => (
                                  <div
                                      key={`${d.title}-${i}`}
                                      className="col-lg-3 col-xs-12 col-md-6 col-sm-12"
                                 style={{paddingTop:"20px"}} >
                                      {" "}
                                      <i className={d.icon}></i>
                                      <h3>{d.title}</h3>
                                      <p>{d.text}</p>
                                  </div>
                              ))
                            : "Loading..."}
                    </div>
                </div>
            </div>
        // </div>
    );
};
