import React from "react";

export const Setup = (props) => {
  return (
    <div id="setup">
      <div className="container">
        <div className="content">
          <h2>READY TO SET UP?</h2>
          <h2>LET US HELP YOU GET STARTED.</h2>
          <p>We are set to help you grow your business today and increase your sales by 50%</p>
          
        </div>

          <a href="https://app.dagizo.io/" className="btn btn-custom2 btn-lg page-scroll">
                  Sign Up Now
                </a>
      </div>
    
    </div>
  );
};
