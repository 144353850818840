import React from "react";

export const Navigation = (props) => {
    return (
        <nav id="menu" className="navbar navbar-default navbar-fixed-top">
            <div className="container">
                <div className="navbar-header">
                    <button
                        type="button"
                        className="navbar-toggle collapsed"
                        data-toggle="collapse"
                        data-target="#bs-example-navbar-collapse-1"
                    >
                        {" "}
                        <span className="sr-only">Toggle navigation</span>{" "}
                        <span className="icon-bar"></span>{" "}
                        <span className="icon-bar"></span>{" "}
                        <span className="icon-bar"></span>{" "}
                    </button>
                    <a className="navbar-brand page-scroll" href="#page-top">
                        <img
                            src="img/Logo.png"
                            className="img-responsive"
                            alt=""
                        />
                    </a>{" "}
                </div>

                <div
                    className="collapse navbar-collapse"
                    id="bs-example-navbar-collapse-1"
                >
                    <ul className="nav navbar-nav navbar-right">
                        <li>
                            <a href="#page-top" className="page-scroll">
                                Home
                            </a>
                        </li>
                        <li>
                            <a href="#features" className="page-scroll">
                                Features
                            </a>
                        </li>
                        <li>
                            <a href="#setup" className="page-scroll">
                                Price plans
                            </a>
                        </li>
                        <li>
                            <a href="#contact" className="page-scroll">
                                Contact Us
                            </a>
                        </li>
                        <a
                            href="https://app.dagizo.io/"
                            className="btn btn-custom3 btn-lg page-scroll"
                        >
                            Sign Up
                        </a>
                    </ul>
                </div>
            </div>
        </nav>
    );
};
