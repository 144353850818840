import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Oneplatfom } from "./components/oneplatfom";
import { Managerequests } from "./components/managerequests";
// import { Customerfeedback } from "./components/customerfeedback";
// import { Services } from "./components/services";
import { Testimonials } from "./components/testimonials";
import { Setup } from "./components/setup";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <Oneplatfom data={landingPageData.Oneplatfom} />
      <Managerequests data={landingPageData.Managerequests} />
      {/* <Customerfeedback data={landingPageData.Customerfeedback} /> */}
      {/* <Services data={landingPageData.Services} /> */}
      <Testimonials data={landingPageData.Testimonials} />
      <Setup data={landingPageData.Setup} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default App;
