import React from "react";

export const Contact = (props) => {
    return (
        <div>
            <div id="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-sm-12 col-xs-12 col-md-12">
                            <img
                                src="img/Logo.png"
                                className="img-responsive"
                                alt=""
                            />
                            <br></br>
                            {/* <div className="contact-item">
                                <p>
                                    <span>
                                        <i className="fa fa-phone"></i> &nbsp;
                                    </span>{" "}
                                    {props.data ? props.data.phone : "loading"}
                                </p>
                            </div> */}
                            <br></br>
                            <div className="contact-item">
                                <p>
                                    <span>
                                        <i className="fa fa-envelope-o"></i>{" "}
                                        &nbsp;
                                    </span>{" "}
                                    {props.data ? props.data.email : "loading"}
                                </p>
                            </div>
                            <br></br>
                            <div className="contact-item">
                                <p>
                                    <span>
                                        <i className="fa fa-map-marker"></i>{" "}
                                        &nbsp;&nbsp;&nbsp;
                                    </span>
                                    {props.data
                                        ? props.data.address
                                        : "loading"}
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-12 col-xs-12 col-md-12">
                            <h2>GO TO PAGES</h2>
                            <div className="contact-item">
                                <p>
                                    <a href="#page-top">Home</a>
                                </p>
                            </div>
                            <div className="contact-item">
                                <p>
                                    <a href="#features">Features</a>
                                </p>
                            </div>
                            <div className="contact-item">
                                <p>
                                    <a href="#setup">Price Plans</a>
                                </p>
                            </div>
                            <div className="contact-item">
                                <p>
                                    <a href="#contact">Contact Us</a>
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12 col-xs-12 col-md-12">
                            <h2>LINKS</h2>
                            <div className="contact-item">
                                <p>
                                    <a href="#page-top">Terms & Conditions</a>
                                </p>
                            </div>
                            <div className="contact-item">
                                <p>
                                    <a href="#oneplatfom">Privacy Policy</a>
                                </p>
                            </div>
                            <div className="contact-item">
                                <p>
                                    <a href="https://app.dagizo.io/">Sign Up</a>
                                </p>
                            </div>
                            <div className="contact-item">
                                <p>
                                    <a href="https://app.dagizo.io/">Sign In</a>
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-12 col-xs-12 col-md-12">
                            {/* <h2>SUBSCRIBE TO OUR NEWSLETTER</h2> */}
                            <div className="col-md-12">
                                {/* <a
                                    href="https://elaborate-dragon-5473c3.netlify.app/signup"
                                    className="btn btn-custom btn-lg page-scroll"
                                    style={{
                                       
                                        padding: "10px",
                                        fontSize: "12px",
                                        display: "block",
                                        textAlign: "center",
                                    }}
                                >
                                    Sign Up Now
                                </a> */}
                                <h2
                                    className=""
                                    style={{ textAlign: "center" }}
                                >
                                    FOLLOW US ON
                                </h2>

                                <div className="social col-sm-12 col-xs-12 col-md-12">
                                    <ul>
                                        <li>
                                            <a
                                                href={
                                                    props.data
                                                        ? props.data.facebook
                                                        : "/"
                                                }
                                            >
                                                <i className="fa fa-facebook"></i>
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a
                                                href={
                                                    props.data
                                                        ? props.data.instagram
                                                        : "/"
                                                }
                                            >
                                                <i className="fa fa-instagram"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href={
                                                    props.data
                                                        ? props.data.twitter
                                                        : "/"
                                                }
                                            >
                                                <i className="fa fa-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href={
                                                    props.data
                                                        ? props.data.youtube
                                                        : "/"
                                                }
                                            >
                                                <i className="fa fa-youtube"></i>
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="footer">
                <div className="container text-center">
                    <p>
                        All rights reserved.Copyright &copy; 2023{" "}
                        <a href="https://app.dagizo.io/" rel="nofollow">
                            Dagizo
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
};
