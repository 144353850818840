import React from "react";
export const Header = (props) => {
  return (
    <header  id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            
            <div className="row">
              <div className="col-md-8 col-md-offset-2 col-sm-12 col-xs-12 col-md-12 intro-text">
                
                <h1 >
                  {props.data ? props.data.title : "Loading"}
                  <span></span><br></br>
                </h1>
                <h2>
                {props.data ? props.data.title1 : "Loading"}
                  <span></span>
                </h2>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <a
                  href="https://app.dagizo.io/"
                  className="btn btn-custom1 btn-lg page-scroll"
                >
                  Sign In
                </a>
                {" "}  &nbsp;
                <a
                  href="https://app.dagizo.io/"
                  className="btn btn-custom2 btn-lg page-scroll">
                  Sign Up
                </a>
                {" "}
              </div>
            </div>
        
          </div>
        </div>
      </div>
      
    </header>
  );
};
